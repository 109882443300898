import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    Stack,
    CssBaseline,
    Box,
    Container,
    Grid,
    Typography,
    CircularProgress,
    Button,
    TextField,
    Snackbar,
    Alert,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import loginFrame from "../img/images/loginFrame.png";
import cookie from "cookiejs";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HomeButton from "../img/icons/HomeButton.png";
import registerFrame from "../img/images/registerFrame.png";
import XemailWarmUp from "../img/icons/XemailWarmUp.png";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const loginTheme = createTheme({
    typography: {
        fontFamily: ["HK Nova", "Inter"].join(","),
    },
});

const Login = (props) => {
    // const classes = loginTheme();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [loginLoading, setLoginLoading] = React.useState(false);
    const [sessionExpired, setSessionExpired] = React.useState(state === null ? false : state.sessionExpired);
    const [accountVerifiedMsg, setAccountVerifiedMsg] = React.useState(state === null ? "" : state.msg);
    const [showPassword, setShowPassword] = React.useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(state === null ? false : state.success);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [verification, setVerification] = useState(false);
    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (isLogin) navigate("/dashboard/email_accounts");
    }, [navigate]);
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    function validateEmail(data) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(data);
    }

    function verify() {
        setVerification(true);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setLoginLoading(true);

        if (!validateEmail(email)) {
            setErrorSnackbarOpen(true);
            setLoginLoading(false);
            setErrorMessage("Enter valid email");
            return;
        } else if (password.length < 6) {
            setErrorSnackbarOpen(true);
            setLoginLoading(false);
            setErrorMessage("Enter valid password");
            return;
        } else {
            console.log(
                new URLSearchParams({
                    email: email,
                    password: password,
                }).toString()
            );
            fetch(`${REACT_APP_SERVER_URL}/login?App_type=Xwarmup`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    password: password,
                    email: email,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response);
                        setLoginLoading(false);
                        navigate("/dashboard/email_accounts");
                        return response.json();
                    } else {
                        setLoginLoading(false);
                        setErrorSnackbarOpen(true);
                        return response.json();
                    }
                })
                .then((data) => {
                    props.User(data.data);
                    console.log(data);
                    if (data.status === 501) {
                        setErrorMessage(data.msg);
                        verify();
                    } else if (data.status === 201) {
                        setErrorMessage(data.msg);
                    }
                });
        }
    };
    const handleSessionExpiredClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSessionExpired(false);
    };
    const handleResetPasswordSuccessSnackbar = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setResetPasswordSuccess(false);
    };
    const handleAccountVerifiedClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAccountVerifiedMsg("");
    };
    console.log(accountVerifiedMsg);
    return (
        <ThemeProvider theme={loginTheme}>
            <CssBaseline />
            <Link exact to="/">
                <img src={HomeButton} alt="brand-logo" style={{ cursor: "pointer", marginBottom: "-20px" }} />
            </Link>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Container width="lg" sx={{ backgroundColor: "#fff", borderRadius: "10px", mt: 2 }}>
                    <Grid container sx={{}}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={7}
                            xl={7}
                            sx={{
                                height: "100%",
                                p: 3,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {/* <img src={loginFrame} alt="Login" style={{ maxWidth: "100%", maxHeight: "100%" }} /> */}
                            <Grid>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    {/* <img src={xCampaignLogo} alt='xlogo' style={{ width: "35%" }} /> */}
                                    <img
                                        src={XemailWarmUp}
                                        alt="xWarmupLogo"
                                        style={{ width: "56%", marginBottom: "-160px", marginTop: "-104px" }}
                                    />
                                </Box>
                                <img
                                    src={registerFrame}
                                    alt="Register"
                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={5}
                            xl={5}
                            sx={{
                                height: "80%",
                                p: 5,
                                py: 8,
                                boxShadow: "0px 32px 100px rgba(50,47,85,0.1)",
                                borderRadius: "40px",
                                padding: "5%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "32px",
                                    fontWeight: "bold",
                                    fontFamily: "'HK Nova', sans-serif",
                                    mb: 4,
                                }}
                            >
                                Hey👋Welcome back!
                            </Typography>
                            <Typography
                                sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif" }}
                            >
                                Email
                            </Typography>
                            <TextField
                                required
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                value={email}
                                fullWidth
                                onChange={(e) => setEmail(e.target.value)}
                                inputProps={{
                                    style: { color: "#9491AD" },
                                }}
                                sx={{
                                    fontFamily: "'Inter', sans-serif",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    backgroundColor: "#f7f7f9",
                                    borderRadius: "8px",
                                    color: "#9491AD",
                                    mb: 3,
                                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                    },
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    pb: 1,
                                    fontWeight: "600",
                                    fontFamily: "'Inter', sans-serif",
                                }}
                            >
                                Password
                            </Typography>
                            <TextField
                                required
                                placeholder="Enter right password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                inputProps={{
                                    style: { color: "#9491AD" },
                                }}
                                sx={{
                                    fontFamily: "'Inter', sans-serif",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    backgroundColor: "#f7f7f9",
                                    borderRadius: "8px",
                                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />

                            <div>
                                {verification && (
                                    <Typography sx={{ pt: 1 }}>
                                        <Link
                                            exact
                                            to="/verifyemail"
                                            style={{
                                                textDecoration: "none",
                                                color: "#3366CC",
                                                float: "left",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            Click here to verify
                                        </Link>
                                    </Typography>
                                )}
                            </div>
                            <Typography sx={{ pt: 1 }}>
                                <Link
                                    exact
                                    to="/forgotPassword"
                                    style={{
                                        textDecoration: "none",
                                        color: "#181818",
                                        fontWeight: "500",
                                        fontFamily: "'Inter', sans-serif",
                                        fontSize: "14px",
                                        float: "right",
                                    }}
                                >
                                    Forgot Password
                                </Link>
                            </Typography>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                onClick={submitHandler}
                                sx={{
                                    mt: 2,
                                    p: 2,
                                    borderRadius: "6px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    backgroundColor: "#481BEB",
                                    fontFamily: "'HK Nova', sans-serif",
                                }}
                                disabled={loginLoading}
                            >
                                {loginLoading && <CircularProgress size={25} />}
                                {!loginLoading && "Login"}
                            </Button>
                            <Stack direction="row" sx={{ mt: 2, fontFamily: "'HK Nova', sans-serif" }}>
                                <Typography sx={{ mr: 0.5, fontSize: "14px" }}>Don't have an account?</Typography>
                                <Link
                                    exact
                                    to="/register"
                                    style={{
                                        color: "#3D17C6",
                                        textDecoration: "none",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                    }}
                                >
                                    Sign Up Here
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                sx={{
                    width: "300px",
                    top: "10px !important",
                    right: "15px !important",
                }}
                open={errorSnackbarOpen}
                autoHideDuration={5000}
                onClose={handleErrorSnackbarClose}
            >
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage === "" ? "Some error occurred" : errorMessage}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                sx={{
                    width: "300px",
                    top: "10px !important",
                    right: "15px !important",
                }}
                open={sessionExpired}
                autoHideDuration={5000}
                onClose={handleSessionExpiredClose}
            >
                <Alert onClose={handleSessionExpiredClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    Session expired, Login again!
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                sx={{
                    width: "300px",
                    top: "10px !important",
                    right: "15px !important",
                }}
                open={accountVerifiedMsg !== ""}
                autoHideDuration={5000}
                onClose={handleAccountVerifiedClose}
            >
                <Alert
                    onClose={handleAccountVerifiedClose}
                    variant="filled"
                    severity={accountVerifiedMsg === "account verified" ? "success" : "error"}
                    sx={{ width: "100%" }}
                >
                    {accountVerifiedMsg}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                sx={{
                    width: "300px",
                    top: "10px !important",
                    right: "15px !important",
                }}
                open={resetPasswordSuccess}
                autoHideDuration={5000}
                onClose={handleResetPasswordSuccessSnackbar}
            >
                <Alert
                    onClose={handleResetPasswordSuccessSnackbar}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Password reset successfully
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default Login;
